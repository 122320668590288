import { createToken, createTokenizer, resolveTokens } from '@solid-primitives/jsx-tokenizer';
import { createSignal, createUniqueId, For, onMount, Show } from 'solid-js';
import createCarousel from 'embla-carousel-solid';
import carouselPluginAutoplay from 'embla-carousel-autoplay';
import carouselPluginAutoScroll from 'embla-carousel-auto-scroll';
import { twMerge } from '@troon/tailwind-preset/merge';
import type { ParentProps } from 'solid-js';

type Props = ParentProps<{
	auto?: 'scroll' | 'play';
	description: string;
	outerClass?: string;
	listClass?: string;
}>;

export function Carousel(props: Props) {
	const tokens = resolveTokens(Tokenizer, () => props.children);
	const [carouselRef, carousel] = createCarousel(
		() => ({ loop: true, inViewThreshold: 0.25 }),
		() =>
			props.auto === 'scroll'
				? [carouselPluginAutoScroll({ stopOnMouseEnter: true, stopOnInteraction: true, speed: 0.5 })]
				: props.auto === 'play'
					? [carouselPluginAutoplay({ stopOnMouseEnter: true, stopOnInteraction: true })]
					: [],
	);
	const [currentSlide, setCurrentSlide] = createSignal(0);
	const [multiSelectable, setMultiSelectable] = createSignal(false);

	onMount(() => {
		const api = carousel();
		if (!api) {
			return;
		}
		api.on('select', (e) => {
			setCurrentSlide(e.selectedScrollSnap());
			setMultiSelectable(api.slidesInView().length > 1);
		});
	});

	let mouseTimeout: NodeJS.Timeout;

	return (
		<div
			class={twMerge('relative overflow-hidden', props.outerClass)}
			onMouseEnter={() => {
				clearTimeout(mouseTimeout);
			}}
			onMouseLeave={() => {
				mouseTimeout = setTimeout(() => {
					carousel()?.plugins().autoplay?.play();
					carousel()?.plugins().autoScroll?.play();
				}, 1000);
			}}
			role="tablist"
			aria-live="off"
			aria-orientation="horizontal"
			aria-roledescription={props.description}
			aria-multiselectable={multiSelectable()}
		>
			<div ref={carouselRef} class="relative overflow-hidden">
				<ul class={twMerge('flex', props.listClass)}>
					<For each={tokens()}>
						{(token, index) => (
							<li
								class={twMerge('shrink-0 grow-0 snap-center object-contain', token.data.class)}
								role="tabpanel"
								aria-label={`Slide ${index() + 1} of ${tokens().length}`}
								aria-roledescription="Slide"
								aria-hidden={currentSlide() !== index()}
								aria-selected={currentSlide() === index()}
							>
								{token.data.children}
							</li>
						)}
					</For>
				</ul>
			</div>
			<Show when={props.auto !== 'scroll'}>
				<ul class="absolute bottom-2 flex w-full justify-center gap-3">
					<For each={tokens()}>
						{(_token, index) => (
							<li>
								<button
									class="group size-8 text-white/60 aria-current:text-white motion-safe:transition-colors motion-safe:duration-500"
									onClick={() => {
										carousel()?.scrollTo(index());
									}}
									role="tab"
									aria-current={currentSlide() === index()}
									aria-label={`Slide ${index() + 1}`}
									aria-roledescription="Slide button"
								>
									<span class="block h-1 rounded bg-current group-hover:bg-white" />
									<span class="sr-only">Jump to slide {index() + 1}</span>
								</button>
							</li>
						)}
					</For>
				</ul>
			</Show>
		</div>
	);
}

type Token = ParentProps<{ type: 'item'; id: string; class?: string }>;

const Tokenizer = createTokenizer<Token>({ name: 'Carousel' });
export const CarouselItem = createToken(Tokenizer, (props: Omit<Token, 'id' | 'type'>) => ({
	...props,
	type: 'item',
	id: createUniqueId(),
}));
